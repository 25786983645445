@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-mb0 {
  margin-bottom: 0 !important; }

.c-topicspath {
  max-width: 1430px;
  width: 100%;
  margin: 0 auto;
  text-align: right;
  position: relative;
  top: -33px; }
  @media screen and (max-width: 1024px) {
    .c-topicspath {
      display: none; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    margin-right: 10px; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: '';
      width: 1px;
      height: 28px;
      background-color: #000000;
      transform: rotate(30deg);
      display: inline-block;
      margin-left: 15px;
      position: relative;
      top: 10px; }
    .c-topicspath li:last-child {
      margin-right: 0; }
      .c-topicspath li:last-child:after {
        content: none; }
    .c-topicspath li > a {
      color: inherit; }
  .c-topicspath .cursor-void:hover {
    cursor: text; }
  .c-topicspath .cursor-void > a:hover {
    cursor: text;
    color: #000000; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 356px;
  background-image: url("/inc/image/common/bg/bg_lowerCaption.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden; }
  @media screen and (max-width: 1460px) {
    .l-lowerCaption {
      padding: 0 15px; } }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 280px; } }
  @media screen and (max-width: 568px) {
    .l-lowerCaption {
      height: 200px; } }
  .l-lowerCaption__inner {
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative; }
  .l-lowerCaption__content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .l-lowerCaption__title {
    margin-bottom: 0;
    font-size: 82px; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 60px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 40px; } }
    .l-lowerCaption__title > small {
      margin-top: 0; }

.l-lower__heroImg {
  width: 80%;
  height: 585px;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -75px;
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
  clip-path: polygon(calc(100% - 55px) 0, 100% 55px, 100% calc(100% - 55px), calc(100% - 55px) 100%, 0 100%, 0 100%, 0 0); }
  @media screen and (max-width: 1024px) {
    .l-lower__heroImg {
      clip-path: polygon(calc(100% - 38.5px) 0, 100% 38.5px, 100% calc(100% - 38.5px), calc(100% - 38.5px) 100%, 0 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 768px) {
    .l-lower__heroImg {
      clip-path: polygon(calc(100% - 27.5px) 0, 100% 27.5px, 100% calc(100% - 27.5px), calc(100% - 27.5px) 100%, 0 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 1024px) {
    .l-lower__heroImg {
      margin-top: -95px;
      height: 300px; } }
  @media screen and (max-width: 768px) {
    .l-lower__heroImg {
      height: 194px;
      width: calc(100% - 20px); } }

.l-lower__msg {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 80px; }
  @media screen and (max-width: 1024px) {
    .l-lower__msg {
      padding: 0 15px; } }
  .l-lower__msg:last-child {
    margin-bottom: 0; }

.l-lower__problem {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 100px; }
  @media screen and (max-width: 1460px) {
    .l-lower__problem {
      padding-left: 20px; } }
  .l-lower__problem:after {
    content: '';
    display: block;
    width: calc(((100% - 1170px) / 2) + 5px);
    height: 100%;
    background-color: #F4F4F4;
    position: absolute;
    top: 0;
    right: 0; }
  .l-lower__problem__inner {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 120px 80px;
    background-color: #F4F4F4;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 55px 100%, 0 calc(100% - 55px), 0 55px, 55px 0);
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .l-lower__problem__inner {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 38.5px 100%, 0 calc(100% - 38.5px), 0 38.5px, 38.5px 0); } }
    @media screen and (max-width: 768px) {
      .l-lower__problem__inner {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 27.5px 100%, 0 calc(100% - 27.5px), 0 27.5px, 27.5px 0); } }
    @media screen and (max-width: 1024px) {
      .l-lower__problem__inner {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 30px;
        padding-bottom: 50px; } }
    @media screen and (max-width: 568px) {
      .l-lower__problem__inner {
        padding-left: 30px;
        padding-right: 20px; } }
  .l-lower__problem .c-topHeading {
    margin-bottom: 0;
    flex-shrink: 0;
    margin-right: 75px; }
    @media screen and (max-width: 1024px) {
      .l-lower__problem .c-topHeading {
        margin: 0 0 20px; } }
  .l-lower__problem .c-list {
    flex-grow: 1; }

.l-lower__feature {
  background-color: #F4F4F4;
  padding-bottom: 120px;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .l-lower__feature {
      margin-bottom: 70px; } }
  @media screen and (max-width: 768px) {
    .l-lower__feature {
      padding-bottom: 80px; } }
  .l-lower__feature:before {
    content: '';
    display: block;
    width: 100%;
    height: 260px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
    @media screen and (max-width: 568px) {
      .l-lower__feature:before {
        height: 200px; } }
  @media screen and (max-width: 1024px) {
    .l-lower__feature:last-child {
      margin-bottom: 0; } }
  .l-lower__feature-inner {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1460px) {
      .l-lower__feature-inner {
        padding: 0 30px; } }
    @media screen and (max-width: 768px) {
      .l-lower__feature-inner {
        padding: 0 15px; } }
  .l-lower__feature-anchor {
    margin-bottom: 60px; }
    .l-lower__feature-anchor-list {
      counter-reset: num; }
      .l-lower__feature-anchor-list > li {
        margin-bottom: 20px; }
        .l-lower__feature-anchor-list > li:last-child {
          margin-bottom: 0; }
        .l-lower__feature-anchor-list > li > a {
          display: block;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          padding: 21px 60px 21px 80px;
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);
          transition: all 0.2s ease !important;
          position: relative; }
          @media screen and (max-width: 568px) {
            .l-lower__feature-anchor-list > li > a {
              font-size: 16px;
              padding: 10px 32px 10px 54px; } }
          .l-lower__feature-anchor-list > li > a:hover {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16); }
          .l-lower__feature-anchor-list > li > a:before {
            content: counter(num, decimal-leading-zero);
            counter-increment: num;
            line-height: 1;
            font-size: 34px;
            font-weight: 600;
            color: #003E92;
            position: absolute;
            top: 1.05em;
            left: 25px;
            transform: translateY(-50%); }
            @media screen and (max-width: 568px) {
              .l-lower__feature-anchor-list > li > a:before {
                font-size: 24px;
                top: 0.9em;
                left: 13px; } }
          .l-lower__feature-anchor-list > li > a:after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            line-height: 1;
            color: #003E92;
            position: absolute;
            top: 50%;
            right: 32px;
            transform: translateY(-50%); }
            @media screen and (max-width: 568px) {
              .l-lower__feature-anchor-list > li > a:after {
                right: 10px; } }
  .l-lower__feature-box {
    counter-reset: feature; }
    .l-lower__feature-box > li {
      background-color: #FFFFFF;
      padding: 70px 50px 60px;
      margin-bottom: 60px; }
      @media screen and (max-width: 768px) {
        .l-lower__feature-box > li {
          padding: 60px 30px 40px; } }
      @media screen and (max-width: 568px) {
        .l-lower__feature-box > li {
          padding: 50px 15px 30px; } }
      .l-lower__feature-box > li:last-child {
        margin-bottom: 0; }
  .l-lower__feature-title {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 25px;
    margin-bottom: 40px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .l-lower__feature-title {
        font-size: 24px;
        padding-bottom: 15px; } }
    @media screen and (max-width: 568px) {
      .l-lower__feature-title {
        font-size: 20px; } }
    .l-lower__feature-title:after {
      content: '';
      width: 100%;
      height: 5px;
      background: linear-gradient(90deg, #008A32 0%, #008A32 33%, #DC000C 33%, #DC000C 66%, #003E92 66%, #003E92 100%);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
    .l-lower__feature-title > span {
      display: block;
      padding-left: 90px; }
      @media screen and (max-width: 768px) {
        .l-lower__feature-title > span {
          padding-left: 65px; } }
      .l-lower__feature-title > span:before {
        content: counter(feature, decimal-leading-zero);
        counter-increment: feature;
        font-size: 64px;
        font-weight: 600;
        line-height: 1;
        color: #003E92;
        position: absolute;
        top: -0.25em;
        left: 0; }
        @media screen and (max-width: 768px) {
          .l-lower__feature-title > span:before {
            font-size: 45px; } }
      .l-lower__feature-title > span:after {
        content: 'FEATURE';
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: #000000;
        position: absolute;
        top: -26px;
        left: 0;
        width: 5.5em; }
        @media screen and (max-width: 768px) {
          .l-lower__feature-title > span:after {
            font-size: 10px;
            top: -19px;
            left: 1px; } }
  .l-lower__feature-subTitle {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5px; }
    @media screen and (max-width: 768px) {
      .l-lower__feature-subTitle {
        font-size: 20px; } }
    @media screen and (max-width: 568px) {
      .l-lower__feature-subTitle {
        font-size: 18px; } }
  .l-lower__feature-img {
    width: 100%; }
  .l-lower__feature-section {
    margin-bottom: 40px; }
    .l-lower__feature-section:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .l-lower__feature-section--main [class*="grid"] .l-lower__feature-img {
        margin-bottom: -20px; } }
    .l-lower__feature-section--child .l-lower__feature-img {
      margin-bottom: 10px; }

.l-lower__case-img {
  margin-bottom: 10px; }
  @media screen and (max-width: 568px) {
    .l-lower__case-img {
      margin-bottom: 5px; } }

.l-lower__case-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5px; }
  @media screen and (max-width: 1280px) {
    .l-lower__case-title {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    .l-lower__case-title {
      font-size: 18px; } }
  .l-lower__case-title:last-child {
    margin-bottom: 0; }

.l-lower__case-txt {
  line-height: 1.7; }

.l-lower__equip-img {
  margin-bottom: 40px; }
